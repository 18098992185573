
    import Vue from 'vue';
    import { Component } from "vue-property-decorator";

    @Component
    export default class App extends Vue {
        name = 'App'

        componentKey = 0

        forceRerenderView() {
            this.componentKey += 1;
        }
    }
