import { Module } from 'vuex'
import { CoreState, initialState } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export const CoreStore: Module<any, CoreState> = {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions
}