export default {
    topic: {
        routes: {

        },
        components: {
            elements: {},
            components: {

            },
            modules: {
                topicList: () => import('../components/modules/TopicList.vue'),
                singleTopic: () => import('../components/modules/SingleTopic.vue'),
            }
        },
        views: {

        }
    }
}