'use strict';

import NodeUtility from "./NodeUtility";

declare global {
    namespace NodeJS {
        interface Global {
            $settings: any;
        }
    }
    interface Window {
        $settings: any;
        API: any;
    }
}

class SettingsUtility
{
    static setSettings(settings: any)
    {
        if (NodeUtility.isNode()) {
            global.$settings = settings;
            return;
        }

        window.$settings = settings;
    }

    static getSettings()
    {
        if (NodeUtility.isNode()) {
            return global.$settings;
        }

        return window.$settings;
    }

    static getSetting(key: any)
    {
        if (NodeUtility.isNode()) {
            return this.parseDotSetting(key, global.$settings);
        }

        return this.parseDotSetting(key, window.$settings);
    }

    static parseDotSetting (keyString: string, settings: any): any
    {
        const key = String(keyString);
        const currentKey = key.slice(0, key.indexOf('.'))
        const subKeys = key.slice(key.indexOf('.') + 1)

        if (typeof settings[key] !== 'undefined') {
            return settings[key]
        } else if (typeof settings[currentKey] === 'object') {
            return this.parseDotSetting(subKeys, settings[currentKey])
        } else {
            return undefined
        }
    }
}

export default SettingsUtility;