export interface CoreState {
    counter: number,
    mainNavigation: any[],
    label_video_draft_item_id: number
    is_student_hint_disabled: boolean
}

export const initialState: CoreState = {
    counter: 0,
    mainNavigation: [],
    label_video_draft_item_id: 0,
    is_student_hint_disabled: false
}