export default {
    assign: {
        routes: {
            detail: {
                path: '/course/:courseId/assign/:id',
                name: 'assign detail'
            },
            create: {
                path: '/course/:courseId/section/:sectionId/assign/create',
                name: 'assign create'
            },
            edit: {
                path: '/course/:courseId/section/:sectionId/module/:moduleId/assign/:id/edit',
                name: 'assign edit'
            }
        },
        components: {
            elements: {},
            components: {},
            modules: {
                assignForm: () => import('../components/modules/AssignForm.vue'),
            }
        },
        views: {
            assignDetail: () => import('../views/AssignDetail.vue'),
            assignCreate: () => import('../views/AssignCreate.vue'),
            assignEdit: () => import('../views/AssignEdit.vue')
        }
    }
}