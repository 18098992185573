import {RouteConfig} from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const assignRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('assign.routes.detail.path'),
        name: SettingsUtility.getSetting('assign.routes.detail.name'),
        component: SettingsUtility.getSetting('assign.views.assignDetail'),
        meta: {
            requiresAuth: true,
            title: 'Aufgabe'
        }
    },
    {
        path: SettingsUtility.getSetting('assign.routes.create.path'),
        name: SettingsUtility.getSetting('assign.routes.create.name'),
        component: SettingsUtility.getSetting('assign.views.assignCreate'),
        meta: {
            requiresAuth: true,
            title: 'Aufgabe anlegen'
        }
    },
    {
        path: SettingsUtility.getSetting('assign.routes.edit.path'),
        name: SettingsUtility.getSetting('assign.routes.edit.name'),
        component: SettingsUtility.getSetting('assign.views.assignEdit'),
        meta: {
            requiresAuth: true,
            title: 'Aufgabe bearbeiten'
        }
    }
]
