import {RouteConfig} from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const quizRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('quiz.routes.detail.path'),
        name: SettingsUtility.getSetting('quiz.routes.detail.name'),
        component: SettingsUtility.getSetting('quiz.views.quizDetail'),
        meta: {
            requiresAuth: true,
            title: 'Quiz'
        }
    },
    {
        path: SettingsUtility.getSetting('quiz.routes.create.path'),
        name: SettingsUtility.getSetting('quiz.routes.create.name'),
        component: SettingsUtility.getSetting('quiz.views.quizCreate'),
        meta: {
            requiresAuth: true,
            title: 'Quiz anlegen'
        }
    },
    {
        path: SettingsUtility.getSetting('quiz.routes.edit.path'),
        name: SettingsUtility.getSetting('quiz.routes.edit.name'),
        component: SettingsUtility.getSetting('quiz.views.quizEdit'),
        meta: {
            requiresAuth: true,
            title: 'Quiz bearbeiten'
        }
    },
    {
        path: SettingsUtility.getSetting('quiz.routes.review.path'),
        name: SettingsUtility.getSetting('quiz.routes.review.name'),
        component: SettingsUtility.getSetting('quiz.views.quizReview'),
        meta: {
            requiresAuth: true,
            title: 'Versuch überprüfen'
        }
    },
    {
        path: SettingsUtility.getSetting('quiz.routes.examList.path'),
        name: SettingsUtility.getSetting('quiz.routes.examList.name'),
        component: SettingsUtility.getSetting('quiz.views.examList'),
        meta: {
            requiresAuth: true,
            title: 'Leistungsabfrage'
        }
    }
]