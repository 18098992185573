import {RouteConfig} from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const surveyRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('survey.routes.create.path'),
        name: SettingsUtility.getSetting('survey.routes.create.name'),
        component: SettingsUtility.getSetting('survey.views.surveyCreate'),
        meta: {
            requiresAuth: true,
            title: 'Umfrage anlegen'
        }
    },
    {
        path: SettingsUtility.getSetting('survey.routes.edit.path'),
        name: SettingsUtility.getSetting('survey.routes.edit.name'),
        component: SettingsUtility.getSetting('survey.views.surveyEdit'),
        meta: {
            requiresAuth: true,
            title: 'Umfrage bearbeiten'
        }
    },
    {
        path: SettingsUtility.getSetting('survey.routes.detail.path'),
        name: SettingsUtility.getSetting('survey.routes.detail.name'),
        component: SettingsUtility.getSetting('survey.views.surveyDetail'),
        meta: {
            requiresAuth: true,
            title: 'Umfrage'
        }
    }
]