'use strict';

class MainNavigationBase
{
    static HEADER = 'header';
    static ITEM = 'item';
    static DIVIDER = 'divider';

    private priority: number;
    private type: any;

    constructor(priority: number, type: any)
    {
        this.priority = priority || 100;
        this.type = type;
    }
}

export default MainNavigationBase;