import Vue from 'vue'
import './registerServiceWorker'
import settings from '@/module.settings'
import { RouterModule } from '@/modules/router'
import { StoreModule } from '@/modules/store'
import { CoreModule } from "@/modules/core"
import { LoginModule } from '@/modules/login'
import {CourseModule} from "@/modules/course"
import {PageModule} from "@/modules/page";
import {ResourceModule} from "@/modules/resource";
import {LabelModule} from "@/modules/label";
import {QuizModule} from "@/modules/quiz";
import {QuestionModule} from "@/modules/question";
import {ScormModule} from "@/modules/scorm";
import {TopicModule} from "@/modules/topic";
import {SurveyModule} from "@/modules/survey";
import {AssignModule} from "@/modules/assign";
import {CalendarModule} from '@/modules/calendar'

function bootstrap() {
    Vue.config.productionTip = false

    settings.loadSettings()

    const routerModule = new RouterModule()
    routerModule.install(Vue)

    const storeModule = new StoreModule()
    storeModule.install(Vue)

    const coreModule = new CoreModule(routerModule.router!, storeModule.store!)
    coreModule.install(Vue)

    const loginModule = new LoginModule(routerModule.router!, storeModule.store!)
    loginModule.install(Vue)

    const courseModule = new CourseModule(routerModule.router!, storeModule.store!)
    courseModule.install(Vue)

    const pageModule = new PageModule(routerModule.router!, storeModule.store!)
    pageModule.install(Vue)

    const resourceModule = new ResourceModule(routerModule.router!, storeModule.store!)
    resourceModule.install(Vue)

    const labelModule = new LabelModule(routerModule.router!, storeModule.store!)
    labelModule.install(Vue)

    const quizModule = new QuizModule(routerModule.router!, storeModule.store!)
    quizModule.install(Vue)

    const questionModule = new QuestionModule(routerModule.router!, storeModule.store!)
    questionModule.install(Vue)

    const scormModule = new ScormModule(routerModule.router!, storeModule.store!)
    scormModule.install(Vue)

    const topicModule = new TopicModule(routerModule.router!, storeModule.store!)
    topicModule.install(Vue)

    const surveyModule = new SurveyModule(routerModule.router!, storeModule.store!)
    surveyModule.install(Vue)

    const assignModule = new AssignModule(routerModule.router!, storeModule.store!)
    assignModule.install(Vue)

    const calendarModule = new CalendarModule(routerModule.router!, storeModule.store!)
    calendarModule.install(Vue)

    // Register beforeEach hook for page reloads (needs to be done before application gets created)
    routerModule.router!.beforeEach(async (to, from, next) => {
        const token = storeModule.store!.getters['login/GET_TOKEN']
        const requiresAuth = to.matched.some(record => record.meta?.requiresAuth)
        const requiresLogout = to.matched.some(record => record.meta?.requiresLogout)
        if (requiresAuth && !token) {
            next('login')
        } else if (requiresLogout && token) {
            next('/')
        } else next()
    })

    // Define page title
    routerModule.router!.afterEach((to, from) => {
        // Use next tick to handle router history correctly
        // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
        Vue.nextTick(() => {
            document.title = 'UHH | ' + (to.meta?.title || 'Moodle');
        });
    });

    // Create application
    coreModule.create(Vue)

    Vue.prototype.$customRouter = routerModule.router;
    Vue.prototype.$customStore = storeModule.store;
}

bootstrap()
