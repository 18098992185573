export default {
    label: {
        routes: {
            detail: {
                path: '/course/:courseId/label/:id',
                name: 'label detail'
            },
            create: {
                path: '/course/:courseId/label/create/:sectionId',
                name: 'create activity label'
            },
            edit: {
                path: '/course/:courseId/label/:activityId/edit',
                name: 'edit activity label'
            }
        },
        components: {
            elements: {},
            components: {
                labelForm: () => import('../components/components/LabelForm.vue')
            },
            modules: {}
        },
        views: {
            labelDetail: () => import('../views/LabelDetail.vue'),
            labelCreate: () => import('../views/LabelCreate.vue'),
            labelEdit: () => import('../views/LabelEdit.vue'),
        }
    }
}