import {LOGIN_USER, LOGOUT_USER, ADD_USER_ID, ADD_USER_DEFAULT_ROLE, ADD_USER_CONTEXT_ID} from './action-types'
import { LoginState } from './state'

export const mutations = {
    INITIALISE(state: LoginState) {
        if (localStorage.getItem('user-token') && localStorage.getItem('user-id') && localStorage.getItem('user-context-id')) {
            state.token = localStorage.getItem('user-token')
            state.userId = parseInt(<string>localStorage.getItem('user-id'))
            state.userContextId = parseInt(<string>localStorage.getItem('user-context-id'))
        }
        if (localStorage.getItem('user-default-role')) {
            state.defaultRole = parseInt(<string>localStorage.getItem('user-default-role'))
        }
    },
    [LOGIN_USER](state: LoginState, token: string) {
        localStorage.setItem('user-token', token)
        state.token = token
    },
    [LOGOUT_USER](state: LoginState) {
        localStorage.setItem('user-token', '')
        localStorage.setItem('user-id', '')
        localStorage.setItem('user-context-id', '')
        localStorage.setItem('user-default-role', '')
        state.token = null
        state.userId = null
        state.userContextId = null
        state.defaultRole = null
    },
    [ADD_USER_ID](state: LoginState, userId: number) {
        localStorage.setItem('user-id', userId.toString())
        state.userId = userId
    },
    [ADD_USER_CONTEXT_ID](state: LoginState, userContextId: number) {
        localStorage.setItem('user-context-id', userContextId.toString())
        state.userContextId = userContextId
    },
    [ADD_USER_DEFAULT_ROLE](state: LoginState, roleId: number) {
        localStorage.setItem('user-default-role', roleId.toString())
        state.defaultRole = roleId
    }
}