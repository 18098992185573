import { INCREASE_COUNTER, DECREASE_COUNTER, ADD_MAIN_NAVIGATION_ITEM, ADD_LABEL_VIDEO_DRAFT_ITEM_ID, SET_STUDENT_HINT_DISABLED } from './action-types'
import { CoreState } from './state'
import MainNavigationBase from "@/modules/core/models/MainNavigationBase";

export const mutations = {
    INITIALISE(state: CoreState) {
        if (localStorage.getItem('is-student-hint-disabled')) {
            state.is_student_hint_disabled = !!parseInt(<string>localStorage.getItem('is-student-hint-disabled'))
        }
    },
    [INCREASE_COUNTER](state: CoreState) {
        ++state.counter
    },
    [DECREASE_COUNTER](state: CoreState) {
        --state.counter
    },
    [ADD_MAIN_NAVIGATION_ITEM](state: CoreState, navigationItem: MainNavigationBase) {
        state.mainNavigation.push(navigationItem)
    },
    [ADD_LABEL_VIDEO_DRAFT_ITEM_ID](state: CoreState, draftItemId: number) {
        state.label_video_draft_item_id = draftItemId
    },
    [SET_STUDENT_HINT_DISABLED](state: CoreState, isDisabled: boolean) {
        const disabled = isDisabled ? '1' : '0'
        localStorage.setItem('is-student-hint-disabled', disabled)
        state.is_student_hint_disabled = isDisabled
    }
}