export default {
    resource: {
        routes: {
            detail: {
                path: '/course/:courseId/resource/:id',
                name: 'resource detail'
            },
            create: {
                path: '/course/:courseId/resource/create/:sectionId',
                name: 'create activity resource'
            },
            edit: {
                path: '/course/:courseId/resource/:activityId/edit',
                name: 'edit activity resource'
            }
        },
        components: {
            elements: {},
            components: {
                resourceForm: () => import('../components/components/ResourceForm.vue')
            },
            modules: {}
        },
        views: {
            resourceDetail: () => import('../views/ResourceDetail.vue'),
            resourceCreate: () => import('../views/ResourceCreate.vue'),
            resourceEdit: () => import('../views/ResourceEdit.vue'),
        }
    }
}