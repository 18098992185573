'use strict';

class MergeUtility
{
    static deepMergeObjects(...objects: any[])
    {
        let target = {};
        // Merge the object into the target object
        let merger = (obj: any) => {
            for (let prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    if (Object.prototype.toString.call(obj[prop]) === '[object Object]'){
                        // If we're doing a deep merge
                        // and the property is an object
                        // @ts-ignore
                        target[prop] = this.deepMergeObjects(target[prop], obj[prop]);
                    } else {
                        // Otherwise, do a regular merge
                        // @ts-ignore
                        target[prop] = obj[prop];
                    }
                }
            }
        };
        //Loop through each object and conduct a merge
        for (let i = 0; i < objects.length; i++) {
            merger(objects[i]);
        }
        return target;
    }
}

export default MergeUtility;