import {Vue as _Vue} from 'vue/types/vue'
import {VueModule} from 'vue-modules'
import VueRouter from 'vue-router'
import {Store} from "vuex";
import {quizRoutes} from './router/quiz-routes'
import MainNavigationItem from "@/modules/core/models/MainNavigationItem";

export class QuizModule implements VueModule {
    readonly name = 'quiz'
    readonly version = '0.0.1'

    constructor(private router: VueRouter, private store: Store<any>) {
    }

    install(Vue: typeof _Vue) {
        this.router.addRoutes(quizRoutes)

        // Add main navigation items
        this.store.dispatch('core/ADD_MAIN_NAVIGATION_ITEM', new MainNavigationItem(400, 'Leistungsabfrage', '/exam/list' , 'note-text'))
    }
}