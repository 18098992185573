import {RouteConfig} from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const questionRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('question.routes.create.essay.path'),
        name: SettingsUtility.getSetting('question.routes.create.essay.name'),
        component: SettingsUtility.getSetting('question.views.questionCreateEssay'),
        meta: {
            requiresAuth: true,
            title: 'Frage anlegen'
        }
    },
    {
        path: SettingsUtility.getSetting('question.routes.create.multichoice.path'),
        name: SettingsUtility.getSetting('question.routes.create.multichoice.name'),
        component: SettingsUtility.getSetting('question.views.questionCreateMultipleChoice'),
        meta: {
            requiresAuth: true,
            title: 'Frage anlegen'
        }
    },
    {
        path: SettingsUtility.getSetting('question.routes.create.ddimageortext.path'),
        name: SettingsUtility.getSetting('question.routes.create.ddimageortext.name'),
        component: SettingsUtility.getSetting('question.views.questionCreateDragDropImage'),
        meta: {
            requiresAuth: true,
            title: 'Frage anlegen'
        }
    },
    {
        path: SettingsUtility.getSetting('question.routes.edit.essay.path'),
        name: SettingsUtility.getSetting('question.routes.edit.essay.name'),
        component: SettingsUtility.getSetting('question.views.questionEditEssay'),
        meta: {
            requiresAuth: true,
            title: 'Frage bearbeiten'
        }
    },
    {
        path: SettingsUtility.getSetting('question.routes.edit.multichoice.path'),
        name: SettingsUtility.getSetting('question.routes.edit.multichoice.name'),
        component: SettingsUtility.getSetting('question.views.questionEditMultipleChoice'),
        meta: {
            requiresAuth: true,
            title: 'Frage bearbeiten'
        }
    },
    {
        path: SettingsUtility.getSetting('question.routes.edit.ddimageortext.path'),
        name: SettingsUtility.getSetting('question.routes.edit.ddimageortext.name'),
        component: SettingsUtility.getSetting('question.views.questionEditDragDropImage'),
        meta: {
            requiresAuth: true,
            title: 'Frage bearbeiten'
        }
    }
]