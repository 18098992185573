import MainNavIcon from '@/modules/core/assets/IconMainNav.vue'
import MainNavActiveIcon from '@/modules/core/assets/IconMainNavActive.vue'

export default {
    icons: {
        values: {
            mainNav: {
                component: MainNavIcon
            },
            mainNavActive: {
                component: MainNavActiveIcon
            }
        }
    },
    theme: {
        options: {
          customProperties: true,
        },
        themes: {
            light: {
                primary: '#00608e',
                secondary: '#f8aa2e',
                ternary: '#87ACDC',
                accent: '#00608e',
                error: '#bc261a',
                info: '#95BED3',
                success: '#C1D186',
                warning: '#f8aa2e',
                white: '#ffffff',
                anchor: '#0B9DE6',
                link: '#0B9DE6',
                darkGrey: '#676767',
                grey: '#8B8B8B',
                lightGrey: '#D0D0D0',
                blue: '#5287CC',
                green: '#5AA665',
                orange: '#D1995A',
                categoryRed: '#CF6B67',
                categoryOrange: '#D1995A',
                categoryYellow: '#CFB45D',
                categoryGreen: '#5AA665',
                categoryBlue: '#5287CC',
                categoryLila: '#9974AB',
                categoryGrey: '#9C9CA0',
            },
        },
    },
    breakpoint: {
        mobileBreakpoint: 1024,
        thresholds: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 1024,
            xl: 1440
        }
    }
};
