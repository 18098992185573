import { Vue as _Vue } from 'vue/types/vue'
import { VueModule } from 'vue-modules'
import VueRouter from 'vue-router'
import { Store } from "vuex";
import { calendarRoutes } from './router/calendar-routes'
import MainNavigationItem from "@/modules/core/models/MainNavigationItem";

export class CalendarModule implements VueModule {
    readonly name = 'calendar'
    readonly version = '0.0.1'
    constructor(private router: VueRouter, private store: Store<any>) {}

    install(Vue: typeof _Vue) {
        this.router.addRoutes(calendarRoutes)

        // Add main navigation items
        this.store.dispatch('core/ADD_MAIN_NAVIGATION_ITEM', new MainNavigationItem(300, 'Stundenplan', '/stundenplan' , 'information'))
    }
}
