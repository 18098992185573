import { Vue as _Vue } from 'vue/types/vue'
import Vuex from 'vuex'
import { VueModule } from 'vue-modules'
import { Store } from 'vuex'

export class StoreModule implements VueModule {
    readonly name = 'store'
    readonly version = '0.0.1'
    store?: Store<any>;
    install(Vue: typeof _Vue) {
        Vue.use(Vuex)
        this.store = new Vuex.Store({
            state: {},
            getters: {},
            mutations: {},
            actions: {},
            modules: {}
        })
    }
}