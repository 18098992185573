'use strict';

class NodeUtility
{
    static isNode()
    {
        try {
            return Object.prototype.toString.call(global.process) === '[object process]'
        } catch(e) {
            return false;
        }
    }
}

export default NodeUtility;