import { RouteConfig } from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const coreRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('core.routes.index.path'),
        name: SettingsUtility.getSetting('core.routes.index.name'),
        component: SettingsUtility.getSetting('core.views.home'),
        meta: {
            requiresAuth: true,
            title: 'Dashboard'
        }
    },
    {
        path: '/AboutUs',
        name: 'AboutUs',
        beforeEnter() {location.href = 'https://www.ascot-vet.net/de/techkom---technologiebasierte-kompetenzmessung-und-foerderung-in-der-elektrotechnischen-und.html'}
    }
]