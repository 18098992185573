export interface LoginState {
    token: string|null,
    userId: number|null,
    userContextId: number|null,
    defaultRole: number|null
}

export const initialState: LoginState = {
    token: null,
    userId: null,
    userContextId: null,
    defaultRole: null
}