export default {
    course: {
        routes: {
            create: {
                path: '/course/create',
                name: 'create course'
            },
            edit: {
                path: '/course/edit/:id',
                name: 'edit course'
            },
            list: {
                path: '/course/list/:category_id?',
                name: 'course list'
            },
            detail: {
                path: '/course/detail/:id',
                name: 'course detail'
            }
        },
        components: {
            elements: {},
            components: {
                courseListCard: () => import('../components/components/CourseListCard.vue')
            },
            modules: {
                courseForm: () => import('../components/modules/CourseForm.vue'),
                courseDetails: () => import('../components/modules/CourseDetails.vue'),
                courseIntro: () => import('../components/modules/CourseIntro.vue'),
                courseInfobox: () => import('../components/modules/CourseInfobox.vue'),
            }
        },
        views: {
            courseCreate: () => import('../views/CourseCreate.vue'),
            courseEdit: () => import('../views/CourseEdit.vue'),
            courseList: () => import('../views/CourseList.vue'),
            courseDetail: () => import('../views/CourseDetail.vue')
        }
    }
}
