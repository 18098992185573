export default {
    calendar: {
        routes: {
            index: {
                path: '/stundenplan',
                name: 'Calendar'
            }
        },
        components: {
            elements: {},
            components: {
                event: () => import('../components/components/Event.vue'),
                eventDetails: () => import('../components/components/EventDetails.vue')
            },
            modules: {
                calendar: () => import('../components/modules/Calendar.vue')
            }
        },
        views: {
            calendar: () => import('../views/Calendar.vue')
        }
    }
}
