import { Vue as _Vue } from 'vue/types/vue'
import { VueModule } from 'vue-modules'
import VueRouter from 'vue-router'
import { Store } from "vuex";
import { pageRoutes } from './router/page-routes'
import MainNavigationItem from "@/modules/core/models/MainNavigationItem";

export class PageModule implements VueModule {
    readonly name = 'page'
    readonly version = '0.0.1'
    constructor(private router: VueRouter, private store: Store<any>) {}

    install(Vue: typeof _Vue) {
        this.router.addRoutes(pageRoutes)
    }
}