import { Vue as _Vue } from 'vue/types/vue'
import { VueModule } from 'vue-modules'
import VueRouter from 'vue-router'
import { Store } from "vuex";
import { loginRoutes } from './router/login-routes'
import MainNavigationItem from "@/modules/core/models/MainNavigationItem";
import { LoginStore } from "@/modules/login/store";

export class LoginModule implements VueModule {
    readonly name = 'login'
    readonly version = '0.0.1'
    constructor(private router: VueRouter, private store: Store<any>) {}

    install(Vue: typeof _Vue) {
        this.router.addRoutes(loginRoutes)
        this.store.registerModule([this.name], LoginStore)

        // Add main navigation items
        this.store.dispatch('core/ADD_MAIN_NAVIGATION_ITEM', new MainNavigationItem(900, 'Profil', '/profile' , 'account'))

        // Initialise login store
        this.store.commit('login/INITIALISE')
    }
}
