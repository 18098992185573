import { RouteConfig } from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const calendarRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('calendar.routes.index.path'),
        name: SettingsUtility.getSetting('calendar.routes.index.name'),
        component: SettingsUtility.getSetting('calendar.views.calendar'),
        meta: {
            requiresAuth: true,
            title: 'Stundenplan'
        }
    }
]
