import Vue from 'vue'
import { ActionTree } from 'vuex'
import { LoginState } from './state'
import {LOGIN_USER, LOGOUT_USER, ADD_USER_ID, ADD_USER_CONTEXT_ID, ADD_USER_DEFAULT_ROLE} from './action-types'

export const actions: ActionTree<any, LoginState> = {
    async [LOGIN_USER]({ commit }, payload: string) {
        commit(LOGIN_USER, payload)
    },
    async [LOGOUT_USER]({ commit }) {
        commit(LOGOUT_USER)
        Vue.prototype.$customRouter.push('/login')
    },
    async [ADD_USER_ID]({ commit }, payload: number) {
        commit(ADD_USER_ID, payload)
    },
    async [ADD_USER_CONTEXT_ID]({ commit }, payload: number) {
        commit(ADD_USER_CONTEXT_ID, payload)
    },
    async [ADD_USER_DEFAULT_ROLE]({ commit }, payload: number) {
        commit(ADD_USER_DEFAULT_ROLE, payload)
    }
}