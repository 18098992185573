import { Vue as _Vue } from 'vue/types/vue'
import { VueModule } from 'vue-modules'
import VueRouter from 'vue-router'
import { Store } from "vuex";
import { courseRoutes } from './router/course-routes'
import MainNavigationItem from "@/modules/core/models/MainNavigationItem";
import Vue from "vue";
import UserUtility from "@/modules/core/utility/UserUtility";

export class CourseModule implements VueModule {
    readonly name = 'course'
    readonly version = '0.0.1'
    constructor(private router: VueRouter, private store: Store<any>) {}

    install(Vue: typeof _Vue) {
        this.router.addRoutes(courseRoutes)

        // Add main navigation items
        this.store.dispatch('core/ADD_MAIN_NAVIGATION_ITEM', new MainNavigationItem(200, 'Situationen', '/course/list' , 'school'))
    }
}
