export default {
    core: {
        routes: {
            index: {
                path: '/',
                name: 'Home'
            }
        },
        components: {
            elements: {},
            components: {
                tukBreadcrumb: () => import('../components/components/TukBreadcrumb.vue'),
                tukHeaderProfile: () => import('../components/components/TukHeaderProfile.vue'),
                tukCopyright: () => import('../components/components/TukCopyright.vue'),
                quicklinkButton: () => import('../components/components/QuicklinkButton.vue'),
                tukToTop: () => import('../components/components/TukToTopButton.vue'),
                teaserCard: () => import('../components/components/TeaserCard.vue'),
            },
            modules: {
                tukHeader: () => import('../components/modules/TukHeader.vue'),
                tukNavigation: () => import('../components/modules/TukNavigation.vue'),
                tukFooter: () => import('../components/modules/TukFooter.vue'),
                dashboardIntro: () => import('../components/modules/DashboardIntro.vue'),
                expansionPanel: () => import('../components/modules/ExpansionPanel.vue'),
                schedulerTaskList: () => import('../components/modules/SchedulerTaskList.vue'),
                tasksList: () => import('../components/modules/TasksList.vue'),
                sectionsList: () => import('../components/modules/SectionsList.vue'),
                gamification: () => import('../components/modules/Gamification.vue'),
                tukCard: () => import('../components/modules/TukCard.vue'),
                activityAreas: () => import('../components/modules/ActivityAreas.vue'),
                actionSituation: () => import('../components/modules/ActionSituation.vue')
            },
            appSkeleton: () => import('../components/AppSkeleton.vue'),
            componentLoader: () => import('../components/ComponentLoader.vue'),
            notification: () => import('../components/Notification.vue'),
        },
        views: {
            home: () => import('../views/Home.vue')
        },
        roles: {
            manager: 1,
            coursecreator: 2,
            editingTeacher: 3,
            teacher: 4,
            student: 5,
            guest: 6,
            user: 7,
            frontpage: 8
        }
    }
}
