import { Vue as _Vue } from 'vue/types/vue'
import { VueModule } from 'vue-modules'
import VueRouter from 'vue-router'
import { Store } from "vuex";
import { resourceRoutes } from './router/resource-routes'

export class ResourceModule implements VueModule {
    readonly name = 'resource'
    readonly version = '0.0.1'
    constructor(private router: VueRouter, private store: Store<any>) {}

    install(Vue: typeof _Vue) {
        this.router.addRoutes(resourceRoutes)
    }
}