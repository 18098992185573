import {Vue as _Vue} from 'vue/types/vue'
import {VueModule} from 'vue-modules'
import VueRouter from 'vue-router'
import {Store} from "vuex";
import {assignRoutes} from './router/assign-routes'

export class AssignModule implements VueModule {
    readonly name = 'assign'
    readonly version = '0.0.1'

    constructor(private router: VueRouter, private store: Store<any>) {
    }

    install(Vue: typeof _Vue) {
        this.router.addRoutes(assignRoutes)
    }
}