import coreSettings from "@/modules/core/settings/core-settings";
import loginSettings from "@/modules/login/settings/login-settings";
import courseSettings from "@/modules/course/settings/course-settings";
import pageSettings from "@/modules/page/settings/page-settings";
import resourceSettings from "@/modules/resource/settings/resource-settings";
import labelSettings from "@/modules/label/settings/label-settings";
import quizSettings from "@/modules/quiz/settings/quiz-settings"
import questionSettings from "@/modules/question/settings/question-settings"
import scormSettings from "@/modules/scorm/settings/scorm-settings"
import topicSettings from "@/modules/topic/settings/topic-settings";
import surveySettings from "@/modules/survey/settings/survey-settings";
import assignSettings from "@/modules/assign/settings/assign-settings";
import calendarSettings from "@/modules/calendar/settings/calendar-settings";
import MergeUtility from "@/modules/core/utility/MergeUtility";
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

let settings = MergeUtility.deepMergeObjects({}, coreSettings);
settings = MergeUtility.deepMergeObjects(settings, loginSettings);
settings = MergeUtility.deepMergeObjects(settings, courseSettings);
settings = MergeUtility.deepMergeObjects(settings, pageSettings);
settings = MergeUtility.deepMergeObjects(settings, resourceSettings);
settings = MergeUtility.deepMergeObjects(settings, labelSettings);
settings = MergeUtility.deepMergeObjects(settings, quizSettings);
settings = MergeUtility.deepMergeObjects(settings, questionSettings);
settings = MergeUtility.deepMergeObjects(settings, scormSettings);
settings = MergeUtility.deepMergeObjects(settings, topicSettings);
settings = MergeUtility.deepMergeObjects(settings, surveySettings);
settings = MergeUtility.deepMergeObjects(settings, assignSettings);
settings = MergeUtility.deepMergeObjects(settings, calendarSettings);

SettingsUtility.setSettings(settings);

export default {
    loadSettings: () => {
        // we need to define and call this function in main.ts in order to load all the settings before anything else
        console.log('Settings loaded')
    }
}
