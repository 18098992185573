import { GetterTree } from 'vuex'
import { CoreState } from './state'

export const GET_COUNTER = 'GET_COUNTER'
export const GET_MAIN_NAVIGATION = 'GET_MAIN_NAVIGATION'
export const GET_LABEL_VIDEO_DRAFT_ITEM_ID = 'GET_LABEL_VIDEO_DRAFT_ITEM_ID'
export const IS_STUDENT_HINT_DISABLED = 'IS_STUDENT_HINT_DISABLED'

export const getters: GetterTree<any, CoreState> = {
    [GET_COUNTER]: (state: CoreState) => state.counter,
    [GET_MAIN_NAVIGATION]: (state: CoreState) => {
        return [...state.mainNavigation].sort((a,b) => {
            if(a['priority'] > b['priority']){return 1}
            if(a['priority'] < b['priority']){return -1}
            return 0
        })
    },
    [GET_LABEL_VIDEO_DRAFT_ITEM_ID]: (state: CoreState) => {
        return state.label_video_draft_item_id
    },
    [IS_STUDENT_HINT_DISABLED]: (state: CoreState) => {
        return state.is_student_hint_disabled
    }
}