import { Module } from 'vuex'
import { LoginState, initialState } from './state'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'

export const LoginStore: Module<any, LoginState> = {
    namespaced: true,
    state: initialState,
    getters,
    mutations,
    actions
}