import { ActionTree } from 'vuex'
import { CoreState } from './state'
import { INCREASE_COUNTER, DECREASE_COUNTER, ADD_MAIN_NAVIGATION_ITEM, ADD_LABEL_VIDEO_DRAFT_ITEM_ID, SET_STUDENT_HINT_DISABLED } from './action-types'
import MainNavigationBase from "@/modules/core/models/MainNavigationBase";

export const actions: ActionTree<any, CoreState> = {
    [INCREASE_COUNTER]({ commit }) {
        commit(INCREASE_COUNTER)
    },
    [DECREASE_COUNTER]({ commit }) {
        commit(DECREASE_COUNTER)
    },
    [ADD_MAIN_NAVIGATION_ITEM]({ commit }, navigationItem: MainNavigationBase) {
        commit(ADD_MAIN_NAVIGATION_ITEM, navigationItem)
    },
    [ADD_LABEL_VIDEO_DRAFT_ITEM_ID]({ commit }, draftItemId: number) {
        commit(ADD_LABEL_VIDEO_DRAFT_ITEM_ID, draftItemId)
    },
    [SET_STUDENT_HINT_DISABLED]({ commit }, isDisabled: boolean) {
        commit(SET_STUDENT_HINT_DISABLED, isDisabled)
    }
}