import { RouteConfig } from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const resourceRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('resource.routes.detail.path'),
        name: SettingsUtility.getSetting('resource.routes.detail.name'),
        component: SettingsUtility.getSetting('resource.views.resourceDetail'),
        meta: {
            requiresAuth: true,
            title: 'Datei'
        }
    },
    {
        path: SettingsUtility.getSetting('resource.routes.create.path'),
        name: SettingsUtility.getSetting('resource.routes.create.name'),
        component: SettingsUtility.getSetting('resource.views.resourceCreate'),
        meta: {
            requiresAuth: true,
            title: 'Datei anlegen'
        }
    },
    {
        path: SettingsUtility.getSetting('resource.routes.edit.path'),
        name: SettingsUtility.getSetting('resource.routes.edit.name'),
        component: SettingsUtility.getSetting('resource.views.resourceEdit'),
        meta: {
            requiresAuth: true,
            title: 'Datei bearbeiten'
        }
    }
]