import {RouteConfig} from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const scormRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('scorm.routes.detail.path'),
        name: SettingsUtility.getSetting('scorm.routes.detail.name'),
        component: SettingsUtility.getSetting('scorm.views.scormDetail'),
        meta: {
            requiresAuth: true,
            title: 'Lernpaket'
        }
    },
    {
        path: SettingsUtility.getSetting('scorm.routes.create.path'),
        name: SettingsUtility.getSetting('scorm.routes.create.name'),
        component: SettingsUtility.getSetting('scorm.views.scormCreate'),
        meta: {
            requiresAuth: true,
            title: 'Lernpaket anlegen'
        }
    },
    {
        path: SettingsUtility.getSetting('scorm.routes.edit.path'),
        name: SettingsUtility.getSetting('scorm.routes.edit.name'),
        component: SettingsUtility.getSetting('scorm.views.scormEdit'),
        meta: {
            requiresAuth: true,
            title: 'Lernpaket bearbeiten'
        }
    },
    {
        path: SettingsUtility.getSetting('scorm.routes.standalonePlayer.path'),
        name: SettingsUtility.getSetting('scorm.routes.standalonePlayer.name'),
        component: SettingsUtility.getSetting('scorm.views.scormStandalonePlayer'),
        meta: {
            requiresAuth: true,
            title: 'Lernpaket Player'
        }
    }
]