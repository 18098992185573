'use strict';

import MainNavigationBase from "./MainNavigationBase";

class MainNavigationItem extends MainNavigationBase
{
    private title: string;
    private route: string;
    private icon: string;

    constructor(priority: number, title: string, route: string, icon: string)
    {
        super(priority, MainNavigationBase.ITEM);
        this.title = title || 'Item Title';
        this.route = route || '/';
        this.icon = icon || 'settings';
    }
}

export default MainNavigationItem;