import { RouteConfig } from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const pageRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('page.routes.detail.path'),
        name: SettingsUtility.getSetting('page.routes.detail.name'),
        component: SettingsUtility.getSetting('page.views.pageDetail'),
        meta: {
            requiresAuth: true,
            title: 'Textseite'
        }
    },
    {
        path: SettingsUtility.getSetting('page.routes.create.path'),
        name: SettingsUtility.getSetting('page.routes.create.name'),
        component: SettingsUtility.getSetting('page.views.pageCreate'),
        meta: {
            requiresAuth: true,
            title: 'Textseite anlegen'
        }
    },
    {
        path: SettingsUtility.getSetting('page.routes.edit.path'),
        name: SettingsUtility.getSetting('page.routes.edit.name'),
        component: SettingsUtility.getSetting('page.views.pageEdit'),
        meta: {
            requiresAuth: true,
            title: 'Textseite bearbeiten'
        }
    }
]