import { Vue as _Vue } from 'vue/types/vue'
import { VueModule } from 'vue-modules'
import VueRouter from 'vue-router'
import { Store } from 'vuex'
import vuetify from './vuetify/vuetify';
import App from './components/App.vue'
import { coreRoutes } from './router/core-routes'
import { CoreStore } from './store'
import MainNavigationItem from "@/modules/core/models/MainNavigationItem"
// @ts-ignore
import CKEditor from '@ckeditor/ckeditor5-vue2'

export class CoreModule implements VueModule {
    readonly name = 'core'
    readonly version = '0.0.1'
    constructor(private router: VueRouter, private store: Store<any>) {}
    install(Vue: typeof _Vue) {
        Vue.use(CKEditor);
        this.router.addRoutes(coreRoutes)
        this.store.registerModule([this.name], CoreStore)

        // Add main navigation items
        this.store.dispatch('core/ADD_MAIN_NAVIGATION_ITEM', new MainNavigationItem(100, 'Dashboard', '/' , 'home'))

        // Initialise core store
        this.store.commit('core/INITIALISE')
    }

    create(Vue: typeof _Vue) {
        new Vue({
            router: this.router,
            store: this.store,
            vuetify,
            render: h => h(App)
        }).$mount('#app')
    }
}
