import { GetterTree } from 'vuex'
import { LoginState } from './state'

export const GET_TOKEN = 'GET_TOKEN'
export const GET_USER_ID = 'GET_USER_ID'
export const GET_USER_CONTEXT_ID = 'GET_USER_CONTEXT_ID'
export const GET_USER_DEFAULT_ROLE = 'GET_USER_DEFAULT_ROLE'

export const getters: GetterTree<any, LoginState> = {
    [GET_TOKEN]: (state: LoginState) => state.token,
    [GET_USER_ID]: (state: LoginState) => state.userId,
    [GET_USER_CONTEXT_ID]: (state: LoginState) => state.userContextId,
    [GET_USER_DEFAULT_ROLE]: (state: LoginState) => state.defaultRole
}