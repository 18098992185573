export default {
    page: {
        routes: {
            detail: {
                path: '/course/:courseId/page/:id',
                name: 'page detail'
            },
            create: {
                path: '/course/:courseId/page/create/:sectionId',
                name: 'create activity page'
            },
            edit: {
                path: '/course/:courseId/page/:activityId/edit',
                name: 'edit activity page'
            }
        },
        components: {
            elements: {},
            components: {
                pageForm: () => import('../components/components/PageForm.vue')
            },
            modules: {}
        },
        views: {
            pageDetail: () => import('../views/PageDetail.vue'),
            pageCreate: () => import('../views/PageCreate.vue'),
            pageEdit: () => import('../views/PageEdit.vue'),
        }
    }
}