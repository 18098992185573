import { RouteConfig } from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const labelRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('label.routes.detail.path'),
        name: SettingsUtility.getSetting('label.routes.detail.name'),
        component: SettingsUtility.getSetting('label.views.labelDetail'),
        meta: {
            requiresAuth: true,
            title: 'Textfeld/Video'
        }
    },
    {
        path: SettingsUtility.getSetting('label.routes.create.path'),
        name: SettingsUtility.getSetting('label.routes.create.name'),
        component: SettingsUtility.getSetting('label.views.labelCreate'),
        meta: {
            requiresAuth: true,
            title: 'Textfeld/Video anlegen'
        }
    },
    {
        path: SettingsUtility.getSetting('label.routes.edit.path'),
        name: SettingsUtility.getSetting('label.routes.edit.name'),
        component: SettingsUtility.getSetting('label.views.labelEdit'),
        meta: {
            requiresAuth: true,
            title: 'Textfeld/Video bearbeiten'
        }
    }
]