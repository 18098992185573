export default {
    scorm: {
        routes: {
            detail: {
                path: '/course/:courseId/scorm/:id',
                name: 'scorm detail'
            },
            create: {
                path: '/course/:courseId/section/:sectionId/scorm/create',
                name: 'scorm create'
            },
            edit: {
                path: '/course/:courseId/section/:sectionId/module/:moduleId/scorm/:id/edit',
                name: 'scorm edit'
            },
            standalonePlayer: {
                path: '/course/:courseId/scorm/:id/standalone-player/:currentAttemptNumber',
                name: 'scorm standalone player'
            }
        },
        components: {
            elements: {},
            components: {},
            modules: {
                scormForm: () => import('../components/modules/ScormForm.vue'),
            }
        },
        views: {
            scormDetail: () => import('../views/ScormDetail.vue'),
            scormCreate: () => import('../views/ScormCreate.vue'),
            scormEdit: () => import('../views/ScormEdit.vue'),
            scormStandalonePlayer: () => import('../views/ScormStandalonePlayer.vue'),
        }
    }
}