export default {
    survey: {
        routes: {
            create: {
                path: '/course/:courseId/section/:sectionId/survey/create',
                name: 'survey create'
            },
            edit: {
                path: '/course/:courseId/section/:sectionId/module/:moduleId/survey/:id/edit',
                name: 'survey edit'
            },
            detail: {
                path: '/course/:courseId/survey/:id',
                name: 'survey detail'
            }
        },
        components: {
            elements: {},
            components: {
                surveyTextarea: () => import('../components/components/SurveyTextarea.vue'),
                surveySelect: () => import('../components/components/SurveySelect.vue'),
                surveyMultichoice: () => import('../components/components/SurveyMultichoice.vue')
            },
            modules: {
                surveyForm: () => import('../components/modules/SurveyForm.vue'),
            }
        },
        views: {
            surveyCreate: () => import('../views/SurveyCreate.vue'),
            surveyEdit: () => import('../views/SurveyEdit.vue'),
            surveyDetail: () => import('../views/SurveyDetail.vue')
        }
    }
}