export default {
    login: {
        routes: {
            login: {
                path: '/login',
                name: 'login',
            },
            profile: {
                path: '/profile',
                name: 'profile',
            }
        },
        components: {
            elements: {},
            components: {},
            modules: {
                login: () => import('../components/modules/Login.vue'),
                profile: () => import('../components/modules/Profile.vue'),
            }
        },
        views: {
            login: () => import('../views/Login.vue'),
            profile: () => import('../views/Profile.vue'),
        }
    }
}