import { RouteConfig } from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const loginRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('login.routes.login.path'),
        name: SettingsUtility.getSetting('login.routes.login.name'),
        component: SettingsUtility.getSetting('login.views.login'),
        meta: {
            requiresLogout: true,
            title: 'Login'
        }
    },
    {
        path: SettingsUtility.getSetting('login.routes.profile.path'),
        name: SettingsUtility.getSetting('login.routes.profile.name'),
        component: SettingsUtility.getSetting('login.views.profile'),
        meta: {
            requiresAuth: true,
            title: 'Profil'
        }
    },
]