import { RouteConfig } from 'vue-router'
import SettingsUtility from "@/modules/core/utility/SettingsUtility";

export const courseRoutes: RouteConfig[] = [
    {
        path: SettingsUtility.getSetting('course.routes.create.path'),
        name: SettingsUtility.getSetting('course.routes.create.name'),
        component: SettingsUtility.getSetting('course.views.courseCreate'),
        meta: {
            requiresAuth: true,
            title: 'Handlungssituation anlegen'
        }
    },
    {
        path: SettingsUtility.getSetting('course.routes.edit.path'),
        name: SettingsUtility.getSetting('course.routes.edit.name'),
        component: SettingsUtility.getSetting('course.views.courseEdit'),
        meta: {
            requiresAuth: true,
            title: 'Handlungssituation bearbeiten'
        }
    },
    {
        path: SettingsUtility.getSetting('course.routes.list.path'),
        name: SettingsUtility.getSetting('course.routes.list.name'),
        component: SettingsUtility.getSetting('course.views.courseList'),
        meta: {
            requiresAuth: true,
            title: 'Handlungssituationen'
        }
    },
    {
        path: SettingsUtility.getSetting('course.routes.detail.path'),
        name: SettingsUtility.getSetting('course.routes.detail.name'),
        component: SettingsUtility.getSetting('course.views.courseDetail'),
        meta: {
            requiresAuth: true,
            title: 'Handlungssituation'
        }
    }
]