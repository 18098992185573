export default {
    question: {
        routes: {
            create: {
                essay: {
                    path: '/course/:courseId/quiz/:quizId/question/create/essay',
                    name: 'question create essay'
                },
                multichoice: {
                    path: '/course/:courseId/quiz/:quizId/question/create/multichoice',
                    name: 'question create multiple choice'
                },
                ddimageortext: {
                    path: '/course/:courseId/quiz/:quizId/question/create/ddimageortext',
                    name: 'question create drag and drop with image'
                }
            },
            edit: {
                essay: {
                    path: '/course/:courseId/quiz/:quizId/question/edit/essay/:questionId',
                    name: 'question edit multiple choice'
                },
                multichoice: {
                    path: '/course/:courseId/quiz/:quizId/question/edit/multichoice/:questionId',
                    name: 'question edit multiple choice'
                },
                ddimageortext: {
                    path: '/course/:courseId/quiz/:quizId/question/edit/ddimageortext/:questionId',
                    name: 'question edit drag and drop image'
                }
            }
        },
        components: {
            elements: {},
            components: {},
            modules: {
                questionForm: () => import('../components/modules/QuestionForm.vue'),
                dragDropImageForm: () => import('../components/modules/DragDropImageForm.vue'),
            }
        },
        views: {
            questionCreateEssay: () => import('../views/QuestionCreateEssay.vue'),
            questionEditEssay: () => import('../views/QuestionEditEssay.vue'),
            questionCreateMultipleChoice: () => import('../views/QuestionCreateMultipleChoice.vue'),
            questionEditMultipleChoice: () => import('../views/QuestionEditMultipleChoice.vue'),
            questionCreateDragDropImage: () => import('../views/QuestionCreateDragDropImage.vue'),
            questionEditDragDropImage: () => import('../views/QuestionEditDragDropImage.vue'),
        }
    }
}