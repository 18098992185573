import { Vue as _Vue } from 'vue/types/vue'
import { VueModule } from 'vue-modules'
import VueRouter from 'vue-router'
import { Store } from "vuex";
import { labelRoutes } from './router/label-routes'

export class LabelModule implements VueModule {
    readonly name = 'label'
    readonly version = '0.0.1'
    constructor(private router: VueRouter, private store: Store<any>) {}

    install(Vue: typeof _Vue) {
        this.router.addRoutes(labelRoutes)
    }
}