export default {
    quiz: {
        routes: {
            detail: {
                path: '/course/:courseId/quiz/:id',
                name: 'quiz detail'
            },
            create: {
                path: '/course/:courseId/section/:sectionId/quiz/create',
                name: 'quiz create'
            },
            edit: {
                path: '/course/:courseId/quiz/:id/edit',
                name: 'quiz edit'
            },
            review: {
                path: '/course/:courseId/quiz/:quizId/review/:attemptId',
                name: 'quiz edit'
            },
            examList: {
                path: '/exam/list',
                name: 'exam list'
            }
        },
        components: {
            elements: {},
            components: {
                examCard: ()=> import('../components/components/ExamCard.vue'),
                quizNavigation: ()=> import('../components/components/QuizNavigation.vue'),
                quizSummary: ()=> import('../components/components/QuizSummary.vue'),
                quizReview: ()=> import('../components/components/QuizReview.vue'),
            },
            modules: {
                questionList: () => import('../components/modules/QuestionList.vue'),
                quizForm: () => import('../components/modules/QuizForm.vue'),
            }
        },
        views: {
            quizDetail: () => import('../views/QuizDetail.vue'),
            quizCreate: () => import('../views/QuizCreate.vue'),
            quizEdit: () => import('../views/QuizEdit.vue'),
            quizReview: () => import('../views/QuizReview.vue'),
            examList: () => import('../views/ExamList.vue')
        }
    }
}